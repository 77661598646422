let current;
let parser = new DOMParser();
let cache = new Map();

async function getPageByHref(href) {
  if (cache.has(href)) return cache.get(href);
  let contents = await fetch(href).then((r) => r.text());
  let doc = parser.parseFromString(contents, "text/html");
  let page = doc.querySelector("body > [data-page]");
  preparePage(page);
  cache.set(href, page);
  return page;
}

async function handleLink(event) {
  event.preventDefault();
  let href = event.target.href;
  window.history.pushState({}, "", href);

  let page = await getPageByHref(href);
  transition("fade", current, page);
  window.scrollTo({ left: 0, top: 0 });

  current = page;
}

async function handlePopstate() {
  let href = window.location.href;

  let page = await getPageByHref(href);
  transition("fade", current, page);
  window.scrollTo({ left: 0, top: 0, behavior: "smooth" });

  current = page;
}

async function transition(name, origin, destination) {
  origin.classList.add(name + "-leave");
  await waitForEvent(origin, "animationend");
  origin.classList.remove(name + "-leave");
  origin.replaceWith(destination);
  destination.classList.add(name + "-enter");
  await waitForEvent(destination, "animationend");
  destination.classList.remove(name + "-enter");
}

function waitForEvent(target, eventName) {
  return new Promise((resolve) => {
    target.addEventListener(
      eventName,
      (event) => {
        if (event.target === target) resolve();
      },
      { once: true },
    );
  });
}

window.addEventListener("popstate", handlePopstate);

document.addEventListener("DOMContentLoaded", () => {
  current = document.querySelector("body > [data-page]");
  cache.set(window.location.href, current);
  preparePage(current);
});

function preparePage(page) {
  let links = page.querySelectorAll("a[href][data-link]");

  for (let link of links) {
    link.addEventListener("click", handleLink);
  }
}

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker.register(new URL("~/layouts/service-worker.js", import.meta.url), {
      type: "module",
    });
  });
}
